$introText: 1.067rem; $text:.940rem; $headerText: 1.070rem; $contentSpace: 3rem; $contentSpaceInline: 1.5rem; $contentSpaceInput: .3rem;
$windowPadding: 24rem; $modalFormPadding: 4rem;

$textColor: #333447; $blue: #0077C7; $darkBlue: #215184; $focusBlue: #7ceffc; $disabledBlue: #adc8e7; $secondaryBlue: #E3F1F9;
$red: #D50057; $error: #c32958; $errorBackground: #f3d4dd; $grey: #d7d9db; $modalBackground: #70707E; $closeButton: #333448;


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');


html {
    background-color: #F5F3F2;
    color: $textColor;
    font-family: 'Open Sans', sans-serif;
}

html, body {
    margin: 0;
    padding: 0 ;
}

#root {
    .hidden {
        display: none;
    }
}

header {
    display: flex;
    align-items: center;
    height: 5rem;
    background-color: $textColor;
    color: white;
    padding: 0 $windowPadding;

    .logo {
        height: 40px;
        width: auto;
        margin-right: .4rem;
    }

    .logo-text_name {
        font-weight: 600;
    }

    .logo-text_orrick {
        color: rgba(255, 255, 255, 0.616);
    }
}

main {
    height: 100%;
    padding: 0 $windowPadding $contentSpace $windowPadding;
}

.file-operations {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 0 1.75rem 0;

    .import, .predict {
        margin-left: $contentSpaceInline;
    }

    input {
        display: none;
    }
}

.form-container {
    min-height: 40rem;
    text-align: center;
    background-color: white;
    padding: $contentSpace 4rem;
    border-radius: .8rem;
    min-width: 300px;

    .intro {
        line-height: 1.47;
        font-size: $introText;
        text-align: left;
    }

    label {
        text-align: left;
    }

    hr {
        margin: $contentSpaceInline 0;
        border: solid $grey .6px;
    }

    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: $contentSpaceInline 0;

        label {
            margin: $contentSpaceInput 0;
            font-size: $headerText;
            font-weight: 600;
        }

        textarea {
            height: 7rem;
        }

        input {
            height: 2.5rem;
            border: none;
            border: solid $textColor 1px;
        }

        textarea, input {
            font-family: 'Open Sans', sans-serif;
            font-size: $text;
            border-radius: .28rem;
            padding: .4rem .8rem;
        }

        .error {
            position: absolute;
            left: 0;
            bottom: -1.3rem;
            font-size: .8rem;
            color: $error;
        }
    }

    .input-container.error-field {
        textarea, input {
            border: none;
            border: solid $error 1px;
        }
    }

    .error-output {
        display: flex;
        align-items: center;
        background-color: $errorBackground;
        margin: $contentSpaceInline 0;
        padding: 1.2rem .8rem;
        font-weight: bold;
        border-radius: .4rem;
        opacity: 1;
        transition: opacity .3s ease-in-out;

        .exclamation {
            padding-right: .8rem;
        }

        .close {
            margin-left: auto;
            cursor: pointer;
        }

        img {
            height: 1.25rem;
            width: auto;
        }
    }

    .output-containers {
        display: flex;
        margin-top: $contentSpace;
    }

    table {
        border: solid $textColor 1px;
        margin: 0;
        text-align: left;
        border-spacing: 0;
        border-radius: .4rem;
        overflow: hidden;
        font-size: $text;

        th {
            font-size: $headerText;
            background-color: $grey;
        }

        th, td {
            padding: 0 1rem;
        }

        .bb {
            border-bottom: solid rgba(113, 113, 113, 0.657) 1px;
        }
    }

    .sector {
        height: 8rem;
        width: 35%;

        thead {
            height: 2.6rem;
        }

        tbody {
            height: 5.2rem;
        }

        td:nth-child(2), th:nth-child(2) {
            width: 2rem;
        }
    }

    .industry {   
        height: 10.4rem;   
        width: 60%;
        margin-left: auto;

        thead {
            height: 2.6rem;
        }

        tbody {
            height: 7.8rem;
        }

        td:nth-child(3), th:nth-child(3) {
            width: 2rem;
        }
    }
}

.main-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 12rem;
        border: none;
        padding: 0;
        margin: $contentSpace auto 0 auto;
        color: white;
        background-color: $blue;
        border-radius: .2rem;
        font-weight: 600;
        font-size: $introText;

        &:active {
            background-color: $darkBlue;
        }

        &:focus {
            box-shadow: 0px 0px 8px 1px #41F2FF;
        }

        &:disabled {
            background-color: $disabledBlue;
        }
    }

.secondary-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.2rem;
    width: 13.5rem;
    border: solid 1px $blue;
    padding: 0;
    margin: 0 0 0 auto;
    color: $blue;
    background-color: white;
    border-radius: .2rem;
    font-weight: 600;
    font-size: $introText;

    &:active {
        background-color: $secondaryBlue;
    }

    &:hover {
        background-color: $secondaryBlue;
    }

    &:focus {
        box-shadow: 0px 0px 5px $focusBlue;
        border: solid $blue 1px;
    }

    img {
        height: 1.25rem;
        width: auto;
        margin: 0 .75rem;
        color: $blue;
    }
}

img {
    height: 100%;
}

// loading css
.loading-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loading-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: 4rem;
    width: 4rem;
    padding: 0;
    // margin: $contentSpaceInline auto $contentSpace auto;
    border: 8px solid $blue;
    border-radius: 50%;
    animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $blue transparent transparent transparent;
  }
  .loading-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loading-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loading-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loading-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.modal {
    z-index: 99;
    background-color: $modalBackground;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - ($windowPadding * 2));
    height: 100%;
    padding: inherit;
    padding-top: $contentSpace;
    text-align: center;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    form {
        position: relative;
        background-color: white;
        padding: $modalFormPadding;
        border-radius: .8rem;
        text-align: left;

        .modal-close {
            position: absolute;
            cursor: pointer;
            top: -1rem;
            right: -1rem;
            background-color: $closeButton;
            color: white;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
        }

        button {
            margin: $contentSpaceInline 0 0 0;
        }
    }

    .error {
        color: $error;
    }

    progress.error {
        accent-color: $error;
    }

    h4 {
        margin: $contentSpaceInline 0;
        font-weight: 400;
        font-size: $headerText;
    }

    progress {
        width: 100%;
    }

    .progress-percent {
        font-weight: bold;
        font-size: .8rem;
    }

    .progress-text {
        font-size: $text;
    }

    .secondary-button {
        margin: 0 auto 0 auto;
    }

    .modalimg {
        width: 0;
        height: 0;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}